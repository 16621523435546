
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "finance-debit-notes-table-component",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      debitNotes: [{} as any],
    };
  },
  setup() {
    const {
      useReusableNavigation,
      displayDatatable,
      reinitialization,
    } = ResusableFunctions();

    return { useReusableNavigation, displayDatatable, reinitialization };
  },
  mounted() {
    this.getdebitNotes();
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getdebitNotes() {
      //Fetch list of all policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_DEDIT_NOTES_ROUTE)
          .then(({ data }) => {
            //Assign data to varibales
            this.debitNotes = data.data;

            if (this.debitNotes) {
              this.displayDatatable(["basicExample"]);
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
